import DesktopProfileMenu from "ui/DesktopProfileMenu";
import LanguageController from "./LanguageContainer";
import SocialMedia from "components/socialMedia";

type ProfileContainerProps = {
  isDesktop?: boolean;
};

const project = process.env.REACT_APP_INSTANCE;

const ProfileContainer = (props: ProfileContainerProps): JSX.Element => {
  return (
    <>
      <SocialMedia
        type={project === "megawin" ? "gradient" : "primary"}
        include={project === "megawin" ? undefined : ["FACEBOOK"]}
      />

      <DesktopProfileMenu />
      {project !== "megawin" && <LanguageController isDesktop={true} />}
    </>
  );
};

export default ProfileContainer;
