import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { useGetGameListMutation } from "../../redux/api/gamesApi";
import { useTypedSelector } from "../../hooks/useTypedRedux";
import ImageIcon from "../../ui/material-kit/ImageIcon";
import InfiniteLoader from "react-window-infinite-loader";
import { FixedSizeGrid, VariableSizeGrid } from "react-window";
import { ReactWindowScroller } from "../../helpers/ReactWindowScroller";
import { IGame } from "../../redux/types/gamesTypes";

interface IContent {
  game?: IGame | null;
  width?: number;
  height?: number;
  left?: number;
  top?: number;
}

const TvGamesPageContainerF = () => {
  const [getGameList, { isLoading }] = useGetGameListMutation();
  const { data, countGames } = useTypedSelector((state) => {
    return {
      data: state.gamesSlice.gameList.toArray().map((item) => item[1]),
      countGames: state.gamesSlice.gameListCount,
    };
  });

  const [page, setPage] = useState(1);

  const hasNextPage = countGames ? data.length < countGames : true;
  const itemCount = hasNextPage ? data.length + 1 : data.length;
  const isItemLoaded = (index: number) => !hasNextPage || index < data.length;

  const loadMoreItems = isLoading
    ? () => {}
    : () => {
        getGameList({ data: { page: page, size: 12 } });
        setPage(page + 1);
      };
  const [mas, setMas] = useState<number[]>([]);

  const params = useMemo(() => {
    return window.innerWidth / 4 - window.innerWidth / 50;
  }, [window.innerWidth]);

  useEffect(() => {
    const mass: number[] = [];

    for (let i = 0; i < countGames; i++) {
      let columnIndex = i % 4;
      let rowIndex = Math.floor(i / 4);
      let ind: number = mass[columnIndex - 1 + rowIndex * 4] || 0;
      let index = rowIndex * 4 + columnIndex + ind;
      if (Math.floor(index / 4) % 4 === 1 && index % 4 === 0 && rowIndex < 5) {
        index += 2;
        ind += 2;
      }
      if (
        (Math.floor(index / 4) % 4 === 0 && index % 4 === 1 && rowIndex < 5) ||
        (Math.floor(index / 4) % 4 === 1 && index % 4 === 1 && rowIndex < 5)
      ) {
        index += 1;
        ind += 1;
      }
      mass.push(ind);
    }
    setMas(mass);
  }, [countGames]);

  const retImage = (columnIndex: number, rowIndex: number): IContent => {
    let index = rowIndex * 4 + columnIndex + mas[columnIndex + rowIndex * 4];
    // console.log(' ri:', rowIndex, ' ci:', columnIndex, 'ind:', ind, index, data[columnIndex + rowIndex * 4].Name, rowIndex % 4 === 0 && columnIndex % 4 === 0)
    if (Math.floor(index / 4) % 4 === 0 && index % 4 === 0 && rowIndex < 5) {
      return {
        game: data[columnIndex + rowIndex * 4],
        width: params * 2,
        height: params * 2,
        left: (index % 4) * params,
        top: Math.floor(index / 4) * params,
      };
    }
    return {
      game: data[columnIndex + rowIndex * 4],
      left: (index % 4) * params,
      top: Math.floor(index / 4) * params,
    };
  };

  const Item = ({ columnIndex, rowIndex, style }: any) => {
    let content: IContent = { game: undefined };
    if (!isItemLoaded(columnIndex + rowIndex * 4)) {
      content.game = undefined;
    } else {
      content = retImage(columnIndex, rowIndex);
    }
    return content ? (
      <Image
        style={style}
        width={content.width}
        height={content.height}
        top={content.top}
        left={content.left}
        alt={content.game?.Thumbnail}
        src={content.game?.Thumbnail}
        onClick={() => {
          // navigate(item.Thumbnail);
          window.scrollTo(0, 0);
        }}
      />
    ) : (
      <div style={style}>{"laoding"}</div>
    );
  };

  return (
    <Wrapper>
      <ReactWindowScroller isGrid={true}>
        {({
          //@ts-ignore
          ref: windowRef,
          //@ts-ignore
          style,
          //@ts-ignore
          onScroll,
        }) => (
          <InfiniteLoader
            isItemLoaded={isItemLoaded}
            itemCount={itemCount}
            loadMoreItems={loadMoreItems}
          >
            {({ onItemsRendered, ref }) => (
              <List
                style={style}
                overscanRowCount={6}
                columnCount={4}
                columnWidth={params}
                rowHeight={params}
                height={window.innerHeight}
                rowCount={Math.ceil(data.length / 4) || 1}
                width={1000}
                itemData={data}
                onScroll={onScroll}
                onItemsRendered={(gridProps) => {
                  onItemsRendered({
                    overscanStartIndex: 0,
                    overscanStopIndex: gridProps.overscanRowStopIndex * 4,
                    visibleStartIndex: 0,
                    visibleStopIndex: gridProps.visibleRowStopIndex * 4,
                  });
                }}
                outerRef={ref}
                ref={windowRef}
              >
                {Item}
              </List>
            )}
          </InfiniteLoader>
        )}
      </ReactWindowScroller>
    </Wrapper>
  );
};

export default TvGamesPageContainerF;

const Wrapper = styled.div`
  display: grid;
  justify-items: center;
  //grid-auto-rows: 1fr;
  //grid-template-columns: repeat(4, 1fr);
  //gap: 17px;
`;

const Game = styled(ImageIcon)`
  width: fit-content;
  height: fit-content;
  border-radius: 10px;
  padding: 10px;
  box-sizing: border-box;
`;

const Image = styled.img<{
  height?: number;
  width?: number;
  top?: number;
  left?: number;
}>`
  width: ${({ width }) => width !== undefined && `${width}px!important`};
  height: ${({ height }) => height !== undefined && `${height}px!important`};
  top: ${({ top }) => top !== undefined && `${top}px!important`};
  left: ${({ left }) => left !== undefined && `${left}px!important`};
  padding: 8px;
  box-sizing: border-box;
  border-radius: 10px;
  //position: relative!important;
`;

const List = styled(FixedSizeGrid)<{ index?: number }>`
  overflow: visible !important;
  //& > div {
  //  position: relative;
  //  display: grid;
  //  grid-template-columns: repeat(4, 250px);
  //  grid-auto-rows: 250px;
  //  & :nth-child(1) {
  //grid-column: 2 span;
  //grid-row: 2 span;
  //grid-area: 1/3/1/3;
  //width: 500px !important;
  //height: 500px !important;
  //}
  //}
`;
