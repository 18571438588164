import { useRef } from "react";
import styled, { css } from "styled-components";
import { useTranslation } from "react-i18next";
import useOutsideClick from "../../hooks/useOutsideClick";
import Modal from "../../ui/material-kit/Modal";
import { IPromotion } from "../../redux/types/appTypes";
import Button from "../../ui/material-kit/Button";
import { useNavigate } from "react-router-dom";
import EmptyIcon from "assets/images/animated_loading_icon.svg";
import { useTypedSelector } from "../../hooks/useTypedRedux";

interface IProfileModal {
  isOpen: boolean;
  onClose: () => void;
  promotion: IPromotion;
  isMobile?: boolean;
}

const ProfileModal = (props: IProfileModal): JSX.Element => {
  const { isOpen, onClose, promotion } = props;
  const navigate = useNavigate();
  const ref = useRef<HTMLDivElement>(null);

  useOutsideClick(ref, () => {
    onClose();
  });

  const { t } = useTranslation();

  const fields = {
    promotions: t(`common.placeholder.username`),
    play: t(`common.modals.promotions.play`),
  };

  const { isMobile } = useTypedSelector((state) => {
    return {
      isMobile: state.appSlice.isMobile,
    };
  });

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={promotion.title}
      isFullScreen={isMobile}
    >
      <Wrapper isMobile={isMobile}>
        {isMobile ? (
          <ImageMobile src={promotion.imageUrl || EmptyIcon} />
        ) : (
          <Image src={promotion.imageUrl || EmptyIcon} />
        )}
        <Content
          isMobile={isMobile}
          dangerouslySetInnerHTML={{ __html: promotion?.content }}
        />
        {promotion.link ? (
          <CustomButton
            onClick={() =>
              navigate(promotion.link.replace(window.location.origin, ""))
            }
            id={`promotions-${promotion.title}`}
          >
            {fields.play}
          </CustomButton>
        ) : (
          <div />
        )}
      </Wrapper>
    </Modal>
  );
};

export default ProfileModal;

const Wrapper = styled.div<{ isMobile?: boolean }>`
  box-sizing: border-box;
  display: grid;
  padding: 0 20px;
  border-radius: 5px;
  text-align: justify;
  align-items: center;
  justify-items: center;
  ${({ isMobile }) =>
    isMobile
      ? css`
          width: 100%;
          max-width: 800px;
          grid-row-gap: 12px;
          height: calc(100vh - 44px - 70px);
          grid-template-rows: minmax(200px, min-content) minmax(200px, 3fr) min-content;
        `
      : css`
          width: 1067px;
          gap: 31px;
          grid-template-columns: 419px 520px;
          grid-template-rows: 381px 1fr;
          padding: 0 58px;

          & :nth-last-child(1) {
            grid-column: 2 span;
          }
        `}
`;

const Content = styled.div<{ isMobile: boolean }>`
  font-size: ${({ isMobile }) => (isMobile ? "16px" : "14px")};
  padding-right: ${({ isMobile }) => (isMobile ? "" : "10px")};
  box-sizing: border-box;
  overflow: auto;
  max-height: 100%;
  width: 100%;
  align-self: start;
  display: flex;
  flex-direction: column;
  height: fit-content;
  text-transform: unset;

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    height: 6px;
    background-color: ${(props) => props.theme.palette.primary.light};
  }

  & > * {
    line-height: 1em;
    color: ${({ theme }) => theme.palette.text.main};
  }

  & > p {
    margin: 0.5em;
    padding: 0;
  }
`;

const Image = styled.img`
  border-radius: 5px;
  max-width: 419px;
  max-height: 381px;
  object-fit: cover;
`;

const ImageMobile = styled.img`
  border-radius: 5px;
  width: 100%;
  max-width: 381px;
  height: 100%;
  object-fit: contain;
`;

const CustomButton = styled(Button)`
  width: 153px;
  text-transform: uppercase;
  margin-bottom: 10px;
`;
