import { Fragment, useEffect } from "react";
import styled, { css } from "styled-components";
import { useTypedSelector } from "../hooks/useTypedRedux";
import { useGetGameListMutation } from "../redux/api/gamesApi";
import GameCard from "./GameCard";
import useDimensions from "react-cool-dimensions";
import { userIdSelector } from "../redux/selectors/userSelector";
import { isMobileSelector } from "../redux/selectors/appSelector";
import { gamesSelector } from "../redux/selectors/gamesSelector";
import { device } from "constants/deviceSizes";

interface IPromotions {
  isDesktop?: boolean;
}

const projectName = process.env.REACT_APP_INSTANCE;

const MainGamesBlock = (props: IPromotions) => {
  const [getGamesList] = useGetGameListMutation();

  useEffect(() => {
    getGamesList({
      data: { size: projectName === "megawin" ? 28 : 30, type: "update" },
    });
  }, [getGamesList]);

  const user = useTypedSelector(userIdSelector);
  const isMobile = useTypedSelector(isMobileSelector);
  const games = useTypedSelector(gamesSelector);

  const { observe } = useDimensions({});

  return (
    <Wrapper>
      {isMobile ? (
        <>
          <ContentMobile>
            {games.slice(0, 30).map((item, index) => (
              <Fragment key={`main-games-card-${index}`}>
                <Game ref={observe}>
                  <GameCard game={item} isAuth={!!user} isTv={false} />
                </Game>
              </Fragment>
            ))}
          </ContentMobile>
        </>
      ) : (
        <>
          <Content>
            {games
              .slice(0, projectName === "megawin" ? 13 : 11)
              .map((item, index) => (
                <Fragment key={`main-games-card-${index}`}>
                  <Game ref={observe}>
                    <GameCard game={item} isAuth={!!user} isTv={false} />
                  </Game>
                </Fragment>
              ))}
          </Content>
        </>
      )}
    </Wrapper>
  );
};

export default MainGamesBlock;

const Wrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding-bottom: 10px;
  overflow: hidden;
`;

const ContentMobile = styled.div`
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(
    auto-fit,
    minmax(
      ${projectName !== "megawin"
        ? "110px"
        : "clamp(170px, 9.500rem + 5.00vw, 248px)"},
      1fr
    )
  );
  gap: 6px;
  grid-template-rows: repeat(
    3,
    ${projectName !== "megawin"
      ? "1fr"
      : "clamp(216px, 12.195rem + 5.57vw, 302px)"}
  );

  margin-top: 6px;

  ${projectName === "megawin" &&
  css`
    grid-template-columns: repeat(4, 1fr);
    grid-auto-flow: row dense;
    grid-template-rows: none;
    gap: 8px;

    @media (${device.tabletXS}) {
      grid-template-columns: repeat(2, 1fr);
    }
  `}
`;

const Content = styled.div`
  box-sizing: border-box;
  display: grid;
  gap: ${projectName !== "megawin" ? "20px" : "17px"};
  grid-template-columns: repeat(7, 1fr);
  grid-auto-rows: 1fr 1fr;

  ${projectName === "megawin" &&
  css`
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: repeat(2, auto);

    @media (${device.laptopL}) {
      row-gap: 8px;
      column-gap: 12px;
    }
  `}

  > div:first-child {
    grid-area: 1/1/3/3;

    ${projectName === "megawin" &&
    css`
      > div > div:nth-child(2) {
        gap: 12px;
        padding-bottom: 32px;

        span:first-child {
          font-size: 36px;
          padding: 0 16px;
          line-height: 30px;
        }

        span:last-child {
          font-size: 24px;
          line-height: 20px;
        }
      }
    `}
  }
`;

const Game = styled.div`
  width: inherit;
  box-sizing: border-box;
  border-radius: 10px;
`;
