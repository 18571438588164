import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "../hooks/useTypedRedux";
import PromotionsCards from "../components/containers/PromotionsCards";
import MainGamesBlock from "../ui/MainGamesBlock";
import Slider from "../components/slider";
import { device } from "../constants/deviceSizes";
import useMediaQuery from "../hooks/useMediaQuery";
import { getStorage } from "../hooks/useStorage";
import ContainerTitle from "../ui/ContainerTitle";
import i18n from "i18next";
import { bannersSelector } from "../redux/selectors/appSelector";
import { userIdSelector } from "../redux/selectors/userSelector";

const Main = (): JSX.Element => {
  const { t } = useTranslation();

  const user = useTypedSelector(userIdSelector);
  const banners = useTypedSelector(bannersSelector);

  const fields = {
    top_games: t(`pages.main.top_games`),
  };
  console.log("init1");
  const CARDS: {
    VISIBLE: boolean;
    PATH: string;
    ICON_PATH: string;
  }[] = getStorage("APP_OTHER.MAIN_CARDS");

  const cards: { imageUrl: string; link: string; lang: string }[] =
    Object.values(CARDS)
      .filter((item) => item.VISIBLE)
      .map((item) => ({
        imageUrl: require(`../${item.ICON_PATH}`),
        link: item.PATH,
        lang: i18n.language,
      }));

  const tablet = useMediaQuery(device.tabletM);

  return (
    <>
      {tablet ? <Slider slides={cards} /> : <Slider slides={banners} />}
      <Wrapper>
        <PromotionsCards isAuth={user !== undefined} />
        <ContainerTitle title={fields.top_games} />
        <MainGamesBlock />
      </Wrapper>
    </>
  );
};

export default Main;

const Wrapper = styled.div`
  padding: 10px 14px;
  @media (${device.mobileL}) {
    padding: 14px 6px;
  }
  box-sizing: border-box;
  justify-content: center;
  overflow: auto;
  grid-template-columns: 100%;
  width: 100%;
  display: grid;
  gap: 14px;
`;
