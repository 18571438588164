import { ReactComponent as ArrowIcon } from "assets/images/icons/arrow_icon.svg";
import { ReactComponent as HelpIcon } from "assets/images/drawer_help_icon.svg";
import { useTypedSelector } from "hooks/useTypedRedux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { TawkService } from "services/TawkService";
import ListMenu from "ui/material-kit/ListMenu";
import { getStorage } from "../../../hooks/useStorage";
import { useCustomToast } from "../../../hooks/useCustomToast";
import { userIdSelector } from "../../../redux/selectors/userSelector";
import { Fragment, useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { ThemeSwitch } from "../header/ThemeSwitch";

const project = process.env.REACT_APP_INSTANCE;

type TypeDrawerContentProps = {
  onClose: () => void;
  setLanguage: (lang: boolean) => void;
};

const DrawerContent = (props: TypeDrawerContentProps): JSX.Element => {
  const { onClose, setLanguage } = props;
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const [isIos, setIsIos] = useState(false);

  useEffect(() => {
    const checkIos = () => {
      if (/iP(ad|od|hone)|Mac/i.test(window.navigator.userAgent)) {
        setIsIos(true);
      }
    };

    checkIos();
  }, []);

  const { pages, langs } = useTypedSelector((state) => {
    return {
      pages: state.appSlice.pages,
      langs: state.appSlice.languages,
    };
  });
  const isAuth = !!useTypedSelector(userIdSelector);
  const callToast = useCustomToast({
    text: t("messages.alert.please_login"),
    type: "error",
  });
  const languages = getStorage("APP_OTHER.LANGUAGES");
  const links = Object.values(getStorage("APP_OTHER.SIDEBAR"));
  const curLang =
    languages[i18n.language.toUpperCase()]?.TITLE || i18n.language;

  const fields = {
    help: t(`common.drawer.help`),
    promotions: t(`common.drawer.promotions`),
    language: t(`common.drawer.language`),
    hall_of_fame: t(`common.drawer.the_hall_of_fame`),
  };

  const handleClick = (func: () => void) => {
    func();
    onClose();
  };

  return (
    <Wrapper>
      {links.map((item: any, index) => (
        <Fragment key={`routing-navbar-link-${index}`}>
          <ListMenu
            leftIconPath={item.ICON_PATH}
            title={`${t(`common.navbar.${item.LANG_KEY}`)}`}
            onClick={() => {
              if (item.AUTH && !isAuth) {
                callToast();
                return;
              }
              navigate(item.PATH);
              onClose();
            }}
          />
        </Fragment>
      ))}

      {project !== "mybet" && project !== "megawin" && (
        <Fragment key={`help-item`}>
          <ListMenu
            title={`${fields.help}`}
            icon={<HelpIcon />}
            onClick={() =>
              handleClick(() => {
                let body = document.getElementsByTagName("body");
                body.item(0)!.style.overflow = "auto";
                TawkService.toggleWidget();
              })
            }
          />
        </Fragment>
      )}

      {project !== "megawin" && (
        <Fragment key={`promotions-item`}>
          <ListMenu
            leftIconPath={`assets/images/navbar/${project}-sidebar/promotions.webp`}
            title={fields.promotions}
            onClick={() => handleClick(() => navigate("/promotions"))}
          />
        </Fragment>
      )}

      {pages
        .filter((item) => item.lang === i18n.language)
        .map((item, index) => (
          <Fragment key={`drawer-list-${index}-${item.title}`}>
            <ListMenu
              leftIconPath={`assets/images/navbar/${project}-sidebar/static_page.webp`}
              title={item.title}
              onClick={() => handleClick(() => navigate(`/static/${item.url}`))}
            />
          </Fragment>
        ))}

      {project === "megawin" && window.pwaInstallPrompt && (
        <Fragment key={"drawer-list-download-button"}>
          <ListMenu
            leftIconPath="assets/images/navbar/megawin-sidebar/download.webp"
            title={t("common.navbar.download")}
            onClick={() =>
              window.pwaInstallPrompt
                ? window.pwaInstallPrompt.prompt()
                : undefined
            }
          />
        </Fragment>
      )}

      {project !== "megawin" && (
        <Fragment key={`drawer-list-language-switcher`}>
          <ListMenu
            leftIconPath={`assets/images/navbar/${project}-sidebar/languages.webp`}
            title={curLang}
            icon={<StyledArrowIcon />}
            onClick={() => setLanguage(true)}
          />
        </Fragment>
      )}

      {project !== "megawin" && (
        <Fragment key={`drawer-list-theme-switcher`}>
          <ThemeSwitch />
        </Fragment>
      )}
    </Wrapper>
  );
};

export default DrawerContent;

const StyledArrowIcon = styled(ArrowIcon)`
  path {
    fill: ${({ theme }) => theme.palette.text.listMenu};
  }
`;

const Wrapper = styled.div`
  ${project === "megawin" &&
  css`
    > *:nth-child(3),
    > *:nth-child(6),
    > *:nth-child(9) {
      margin-bottom: 24px;
    }
  `}

  ${project === "mybet" &&
  css`
    > *:nth-child(4),
    > *:nth-child(8),
    > *:nth-last-child(2) {
      margin-bottom: 24px;
    }

    > *:last-child {
      margin-bottom: 15px;
    }
  `}
`;
