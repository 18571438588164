import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled, { css } from "styled-components";
import { useGetGameListMutation } from "../../redux/api/gamesApi";
import { useTypedSelector } from "../../hooks/useTypedRedux";
import GameCard from "../../ui/GameCard";
import InfiniteScroll from "react-infinite-scroll-component";
import useMediaQuery from "../../hooks/useMediaQuery";
import { device } from "../../constants/deviceSizes";
import useDimensions from "react-cool-dimensions";
import { gamesPageSelector } from "../../redux/selectors/gamesSelector";
import { userSelector } from "../../redux/selectors/userSelector";
import { IGame } from "../../redux/types/gamesTypes";
import { MEGAWIN_GAMES_ASPECT_RATIO } from "constants/config";

const project = process.env.REACT_APP_INSTANCE;

interface IGamesPageContainer {
  value: {
    game?: string;
    provider?: string;
    category?: string;
  };
}

const MemoGameCard = React.memo(
  ({ item, minHeight }: { item: IGame; minHeight: number }) => {
    return (
      <Game>
        <GameCard
          game={item}
          style={
            project === "megawin"
              ? {}
              : {
                  maxHeight: minHeight + 28,
                  maxWidth: minHeight,
                  minHeight: minHeight + 28,
                }
          }
          isLazy={true}
        />
      </Game>
    );
  }
);

const GamesVirtualListContainer = (props: IGamesPageContainer) => {
  const { game, provider, category } = props.value;
  const [getGameList, { isLoading }] = useGetGameListMutation();

  const { data, countGames } = useTypedSelector(gamesPageSelector);

  const [page, setPage] = useState(1);

  useEffect(() => {
    getGameList({
      data: {
        type: "update",
        title: game,
        size: 15,
        page: 1,
        provider,
        category,
      },
    });
    setPage(2);
  }, [game, provider, category]);

  const [hasMoreItems, setHasMoreItems] = useState(true);

  const loadMore = useCallback(() => {
    if (data.length >= countGames) {
      setHasMoreItems(false);
      return;
    }
    setHasMoreItems(true);
    let newPage = page + 1;
    setPage(newPage);
    getGameList({
      data: {
        page,
        title: game,
        provider,
        size: 15,
        category,
      },
    });
  }, [category, countGames, data.length, game, getGameList, page, provider]);

  const tablet = useMediaQuery(device.tabletS);
  const { observe, width } = useDimensions({});

  const gameCardColumns =
    project === "megawin" ? (tablet ? 3 : 4) : tablet ? 2 : 4;
  const gameCardGap = project === "megawin" ? 8 : 10;

  const minHeight = useMemo(() => {
    if (project === "megawin") {
      return (
        (width / gameCardColumns - gameCardGap) / MEGAWIN_GAMES_ASPECT_RATIO
      );
    }
    return width / gameCardColumns - gameCardGap;
  }, [width, gameCardColumns, gameCardGap]);

  const loader = useMemo(() => <></>, []);

  const stylesInfinite = useMemo(() => {
    return project === "megawin"
      ? {
          display: "grid",
          gridTemplateColumns: `repeat(${gameCardColumns}, 1fr)`,
          gridGap: "8px",
          height: "100%",
          overflow: "hidden",
          gridAutoRows: "row dense",
          padding: width <= 590 ? "0" : "8px",
        }
      : {
          display: "grid",
          gridTemplateColumns: `repeat(${gameCardColumns}, 1fr)`,
          gridGap: "4px",
          height: "100%",
          overflow: "hidden",
          gridAutoRows: "min-content",
        };
  }, [width, gameCardColumns]);

  return (
    <Wrapper ref={observe}>
      <InfiniteScroll
        dataLength={data.length}
        next={loadMore}
        style={stylesInfinite}
        hasMore={hasMoreItems && data.length !== 0}
        loader={loader}
      >
        {data.map((item, index) => (
          <React.Fragment key={`game-${index}`}>
            <MemoGameCard item={item} minHeight={minHeight} />
          </React.Fragment>
        ))}
      </InfiniteScroll>
    </Wrapper>
  );
};

export default React.memo(GamesVirtualListContainer);

const Wrapper = styled.div`
  max-width: calc(100vw - 10px);
`;

const Game = styled.div`
  min-width: 100px;
  min-height: 100px;
  padding: 4px;
  box-sizing: border-box;
  border-radius: 10px;

  ${project === "megawin" &&
  css`
    padding: 0;
  `}
`;
