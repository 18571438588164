import { memo, useMemo } from "react";
import styled, { css, useTheme } from "styled-components";
import { useNavigate } from "react-router-dom";
import { ReactComponent as MegaphoneIcon } from "assets/images/header_megaphone_icon.svg";
import InfoContainer from "./InfoContainer";
import { useTranslation } from "react-i18next";
import { getStorage } from "hooks/useStorage";
import ImageIcon from "ui/material-kit/ImageIcon";
import CurrentDate from "ui/CurrentDate";
import Button from "ui/material-kit/Button";
import ProfileContainer from "./ProfileContainer";
import { useTypedSelector } from "../../../hooks/useTypedRedux";
import { device } from "constants/deviceSizes";
import { isMobileSelector } from "redux/selectors/appSelector";
import { ThemeSwitch } from "./ThemeSwitch";

const project = process.env.REACT_APP_INSTANCE;

const HeaderDesktop = () => {
  const theme = useTheme();
  const logo = useMemo(() => {
    const PATH = getStorage("APP_SEO.LOGO_PATH");
    return require(`../../../${PATH[theme.mode.toLocaleUpperCase()]}`);
  }, [theme.mode]);

  const { user } = useTypedSelector((state) => {
    return {
      user: state.userSlice.user,
    };
  });

  const { t, i18n } = useTranslation();
  const isRight = i18n.dir() === "rtl";

  const isMobile = useTypedSelector(isMobileSelector);

  const navigate = useNavigate();
  return (
    <Container isRight={isRight}>
      <ImageIcon
        style={{
          width: project === "megawin" ? 240 : 225,
          height: project === "megawin" ? 45 : "auto",
          justifySelf: "flex-start",
        }}
        src={logo}
        alt={"desktop-logo"}
        onClick={() => {
          navigate("/");
        }}
      />

      <Wrapper
        isRight={isRight}
        style={{
          justifySelf: "center",
        }}
      >
        <CurrentDate />
        <PromoButton
          id={`go_to_promotions`}
          isMobile={isMobile}
          onClick={() => navigate("/promotions")}
          style={{ height: 45, padding: "0 16px", minWidth: "auto" }}
          color={"secondary"}
        >
          <PromoContainer>
            <MegaphoneIcon />
            {t(`common.header.promotions`)}
          </PromoContainer>
        </PromoButton>
      </Wrapper>

      {project !== "megawin" && <ThemeSwitch />}

      {user && (
        <>
          <InfoContainer />
        </>
      )}

      <Wrapper>
        <ProfileContainer />
      </Wrapper>
    </Container>
  );
};

export default memo(HeaderDesktop);

const Container = styled.div<{ isRight?: boolean }>`
  position: relative;
  z-index: 1000000;
  padding: 0 40px;
  width: 100%;
  height: 70px;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.palette.background.header};
  display: flex;
  align-items: center;
  gap: 20px;

  @media (${device.laptopL}) {
    padding: 0 10px;
  }

  > *:first-child {
    ${({ isRight }) =>
      isRight
        ? "margin-left"
        : "margin-right"}: clamp(1.25rem, 20.968vw - 15.786rem, 9.375rem);
  }
`;

const Wrapper = styled.div<{ isRight?: boolean }>`
  display: flex;
  gap: 16px;
  justify-content: flex-start;
  align-items: center;

  &:nth-child(2) {
    ${({ isRight }) => (isRight ? "margin-left" : "margin-right")}: auto;
    gap: clamp(1.25rem, 8.065vw - 5.302rem, 4.375rem);
  }
`;

const PromoButton = styled(Button)<{ isMobile: boolean }>`
  &:hover {
    background-color: ${({ theme, isMobile }) =>
      !isMobile && project !== "megawin"
        ? theme.palette.primary.black
        : theme.palette.secondary.focused};
  }
`;

const PromoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
  color: ${({ theme }) => theme.palette.primary.light};

  svg path {
    fill: ${({ theme }) => theme.palette.primary.main};
  }
`;
