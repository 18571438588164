import React from "react";
import { ReactComponent as RulesIcon } from "assets/images/drawer_rules_icon.svg";
import { ReactComponent as HelpIcon } from "assets/images/drawer_help_icon.svg";
import { ReactComponent as MegaphoneIcon } from "assets/images/header_megaphone_icon.svg";
import { useTypedSelector } from "hooks/useTypedRedux";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import ListMenu from "../../../ui/ListMenu";
import { useNavigate } from "react-router-dom";
import { TawkService } from "../../../services/TawkService";

type DrawerContentProps = {
  onClose: () => void;
};

const DrawerContent = (props: DrawerContentProps): JSX.Element => {
  const { onClose } = props;
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { pages, langs } = useTypedSelector((state) => {
    return {
      pages: state.appSlice.pages,
      langs: state.appSlice.languages,
    };
  });

  const fields = {
    help: t(`common.drawer.help`),
    promotions: t(`common.drawer.promotions`),
    language: t(`common.drawer.language`),
    hall_of_fame: t(`common.drawer.the_hall_of_fame`),
  };

  const handleClick = (func: () => void) => {
    func();
    onClose();
  };

  return (
    <>
      <Container>
        <React.Fragment key={`help-item`}>
          <ListMenu
            title={`${fields.help}`}
            click={() =>
              handleClick(() => {
                let body = document.getElementsByTagName("body");
                body.item(0)!.style.overflow = "auto";
                TawkService.toggleWidget();
              })
            }
            icon={<HelpIcon />}
          />
        </React.Fragment>

        <React.Fragment key={`promotions-item`}>
          <ListMenu
            title={fields.promotions}
            icon={<StMegaphoneIcon />}
            click={() => handleClick(() => navigate("/promotions"))}
          />
        </React.Fragment>
        <React.Fragment key={`language-item`}>
          <ListMenu
            title={
              langs.find((lang) => lang.code === i18n.language)?.title ||
              fields.language
            }
            isLang={true}
          />
        </React.Fragment>

        {pages
          .filter((item) => item.lang === i18n.language)
          .map((item, index) => (
            <React.Fragment key={`drawer-list-${index}-${item.title}`}>
              <ListMenu
                title={item.title}
                icon={<RulesIcon />}
                click={() => handleClick(() => navigate(`/static/${item.url}`))}
              />
            </React.Fragment>
          ))}
      </Container>
    </>
  );
};

export default DrawerContent;

const Container = styled.div`
  display: grid;
  grid-auto-rows: minmax(56px, 1fr);
  gap: 10px;
  overflow-wrap: anywhere;
  grid-template-columns: 1fr 1fr;

  > div {
    grid-column: 2 span;

    :nth-child(1),
    :nth-child(2) {
      grid-column: 1 span;
      flex-direction: column;
      justify-content: space-between;
    }
  }
`;

const StMegaphoneIcon = styled(MegaphoneIcon)`
  & path {
    fill: ${({ theme }) => theme.palette.text.main};
  }
`;
