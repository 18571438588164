import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTypedSelector } from "hooks/useTypedRedux";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";

import styled from "styled-components";
import useDimensions from "react-cool-dimensions";
import BannerImage from "assets/images/tv_games_banner_icon.webp";
import MegawinBannerImage from "assets/images/tv_games_megawin_banner_icon.webp";
import TvGamesPageContainer from "./TvGamesListContainer";
import Search from "../../ui/material-kit/Search";
import useInput from "../../hooks/useInput";
import {
  useGetGameListMutation,
  useGetProvidersQuery,
  useGetProvidersTvQuery,
} from "../../redux/api/gamesApi";
import { useDebounce } from "../../hooks/useDebounce";
import ProvidersSwiper from "../../components/containers/ProvidersSwiper";

const project = process.env.REACT_APP_INSTANCE;

const TvGamesDesktop = () => {
  const { t } = useTranslation();
  const {} = useGetProvidersTvQuery();
  const search = useInput("");

  const fields = {
    search: t(`common.placeholder.search_tv_games`),
    password: t(`common.placeholder.password`),
  };

  const [searchParams, setSearchParams] = useSearchParams();
  const [provider, setProvider] = useState<string>(
    searchParams.get("provider") || "all"
  );
  const findGames = useDebounce(search.value, 1000);

  const changeProvider = useCallback(
    (v: string) => {
      setSearchParams({ provider: v });
    },
    [provider]
  );

  useEffect(() => {
    const prov = searchParams.get("provider") || "";
    if (prov !== provider) setProvider(prov);
  }, [searchParams]);

  console.log(provider);

  const memoValue = useMemo(() => {
    return {
      game: findGames || undefined,
      provider: provider || undefined,
    };
  }, [findGames, provider]);

  return (
    <>
      <Banner>
        <Image
          alt={"banner"}
          src={project === "megawin" ? MegawinBannerImage : BannerImage}
        />
      </Banner>
      <DesktopContainer>
        <Search search={search} title={fields.search} isMobile={false} />
        <ProvidersSwiper
          changeProvider={changeProvider}
          currentProvider={provider}
        />
        <TvGamesPageContainer value={memoValue} />
      </DesktopContainer>
    </>
  );
};

export default TvGamesDesktop;

const DesktopContainer = styled.div`
  padding: 16px 40px;
  gap: 20px;
  display: grid;
  grid-auto-rows: min-content;
`;

const Banner = styled.div`
  width: 100%;
  height: 100%;
`;

const Image = styled.img`
  width: inherit;
`;
