import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { useGetGameListMutation } from "../../redux/api/gamesApi";
import { useTypedSelector } from "../../hooks/useTypedRedux";
import useDimensions from "react-cool-dimensions";

import InfiniteScroll from "react-infinite-scroll-component";
import { IGame } from "../../redux/types/gamesTypes";
import GameCard from "../../ui/GameCard";
import { MEGAWIN_GAMES_ASPECT_RATIO } from "constants/config";

interface IGamesPageContainer {
  value: {
    game?: string;
    provider?: string;
    category?: string;
  };
}

const project = process.env.REACT_APP_INSTANCE;

const MemoGameCard = React.memo(
  ({ item, minHeight }: { item: IGame; minHeight: number }) => {
    return (
      <Game style={{ height: minHeight }}>
        <GameCard
          isTv={false}
          game={item}
          style={{ minHeight: minHeight / 2 }}
          isLazy={true}
        />
      </Game>
    );
  }
);

// количество колонок с карточками игр
const gameCardColumns = project === "megawin" ? 8 : 7;
const gamesLoadCount = project === "megawin" ? 30 : 22;

const GamesVirtualListContainerDesktop = (props: IGamesPageContainer) => {
  const { game, provider, category } = props.value;
  const [getGameList] = useGetGameListMutation();
  const { data, countGames } = useTypedSelector((state) => {
    return {
      user: state.userSlice.user,
      data: state.gamesSlice.gameList
        .toArray()
        .map((item) => item[1])
        .slice(gamesLoadCount, 1000),
      countGames: state.gamesSlice.gameListCount - gamesLoadCount,
    };
  });

  const [page, setPage] = useState(2);

  useEffect(() => {
    setPage(2);
    getGameList({
      data: {
        type: "update",
        page: 1,
        title: game,
        provider,
        category,
        // todo: по неведомой причине, если загрузить ровно gamesLoadCount игр вначале, перестает работать infiniteScroll
        size: gamesLoadCount + 1,
      },
    });
  }, [game, provider]);

  const { observe, width } = useDimensions({});
  const [hasMoreItems, setHasMoreItems] = useState(true);

  const loadMore = useCallback(() => {
    if (data.length >= countGames) {
      setHasMoreItems(false);
      return;
    }
    setHasMoreItems(true);
    let newPage = page + 1;
    setPage(newPage);
    getGameList({
      data: {
        page,
        title: game,
        provider,
        category,
        size: gamesLoadCount,
      },
    });
  }, [countGames, data.length, game, getGameList, page, provider]);

  const gameCardHeight = useMemo(() => {
    let height;
    // отступ между карточками
    const gameCardGap = width > 1360 ? 16 : 12;

    // здесь вычисляется высота для карточек игр

    if (project === "megawin")
      height =
        ((width - gameCardGap) / gameCardColumns - gameCardGap) /
        MEGAWIN_GAMES_ASPECT_RATIO;
    else height = width / gameCardColumns;

    return height;
  }, [width, gameCardColumns]);

  const stylesInfinite = useMemo(() => {
    return project === "megawin"
      ? {
          display: "grid",
          gridTemplateColumns: `repeat(${gameCardColumns}, 1fr)`,
          columnGap: width <= 1360 ? "12px" : "18px",
          rowGap: width <= 1360 ? "12px" : "18px",
          height: "100%",
          overflow: "hidden",
          gridAutoRows: "1fr",
          maxWidth: "100%",
          padding: width <= 1360 ? "6px" : "8px",
        }
      : {
          display: "grid",
          gridTemplateColumns: `repeat(${gameCardColumns}, 1fr)`,
          gridGap: "12px",
          height: "100%",
          overflow: "hidden",
          gridAutoRows: "min-content",
        };
  }, [width]);

  const loader = useMemo(() => <></>, []);

  return (
    <div ref={observe} id={`scrollableTvGames`}>
      <InfiniteScroll
        scrollableTarget={`main-wrapper`}
        dataLength={data.length}
        next={loadMore}
        style={stylesInfinite}
        hasMore={hasMoreItems && data.length !== 0}
        loader={loader}
      >
        {data.map((item, index) => (
          <React.Fragment key={`game-${index}`}>
            <MemoGameCard item={item} minHeight={gameCardHeight} />
          </React.Fragment>
        ))}
      </InfiniteScroll>
    </div>
  );
};

export default GamesVirtualListContainerDesktop;

const Game = styled.div`
  box-sizing: border-box;
`;
