import { useCallback, useEffect, useState } from "react";
import { useCreateDigitainSessionMutation } from "redux/api/gamesApi";
import { useTypedSelector } from "hooks/useTypedRedux";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import Loader from "../../ui/Loader/Loader";
import { isMobileSelector } from "redux/selectors/appSelector";
import { useLocation } from "react-router-dom";

const project = process.env.REACT_APP_INSTANCE;

const DigitainSport = () => {
  const { user } = useTypedSelector((state) => {
    return {
      user: state.userSlice.user,
    };
  });

  const isMobile = useTypedSelector(isMobileSelector);
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const [CreateDigitainSession, { isLoading }] =
    useCreateDigitainSessionMutation();
  const [url, setUrl] = useState<string>("");
  const [IframeLoaded, setIframeLoaded] = useState(false);

  const main = useCallback(async () => {
    const digitain = await CreateDigitainSession({
      isMobile,
      isLive: pathname.includes("live-sport"),
    }).then((resp) => {
      const iframe = document.querySelector("iframe");

      if ("data" in resp) {
        if (iframe && iframe.contentWindow) {
          iframe.src = "about:blank";
          iframe.contentWindow.document.open();
          iframe.contentWindow.document.write(resp.data.SessionHTML);
          iframe.contentWindow.document.close();
        }
      }
    });
  }, [CreateDigitainSession, isMobile, pathname]);

  useEffect(() => {
    main();
  }, [main, user?.id]);

  return (
    <SportContainer isMobile={isMobile}>
      {(!IframeLoaded || isLoading) && (
        <LoaderWrapper>
          <Loader width={300} />
        </LoaderWrapper>
      )}
      <Iframe src={url} onLoad={() => setIframeLoaded(true)} allowFullScreen />
    </SportContainer>
  );
};

export default DigitainSport;

const Iframe = styled.iframe`
  position: absolute;
  width: 100%;
  height: 100%;
  border: none;
  z-index: 400;
  left: 0;
`;

const SportContainer = styled.section<{ isMobile: boolean }>`
  width: 100%;
  height: calc(100% - 138px);
  top: 138px;
  position: fixed;
  left: 50%;
  transform: translate(-50%, 0);
  @media (max-width: 1024px) {
    transform: none;
    left: inherit;
    height: calc(100% - 45px);
    top: ${({ isMobile }) =>
      isMobile && project === "megawin" ? "44px" : "18px"};
  }
`;

const LoaderWrapper = styled.span`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 401;
  background-color: rgba(0, 0, 0, 0.59);
  display: flex;
  justify-content: center;
  align-items: center;
`;
